var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"Ingrese un número de documento para buscar al cliente ..."},model:{value:(_vm.documentNumberSearch),callback:function ($$v) {_vm.documentNumberSearch=$$v},expression:"documentNumberSearch"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.searchClientOnBasedDocumentNumber}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1)],1)],1),_c('hr'),_c('hr')],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"NOMBRE(S)","label-for":"names"}},[_c('validation-provider',{attrs:{"rules":"required","name":"nombre(s)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"names","placeholder":"Ingrese el nombre del cliente ...","state":errors.length > 0 ? false : null,"disabled":_vm.disabledFields,"trim":""},model:{value:(_vm.names),callback:function ($$v) {_vm.names=$$v},expression:"names"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"APELLIDO PATERNO","label-for":"first_surname"}},[_c('validation-provider',{attrs:{"rules":"required","name":"apellido paterno"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first_surname","placeholder":"Ingrese el apellido paterno del cliente ...","state":errors.length > 0 ? false : null,"disabled":_vm.disabledFields,"trim":""},model:{value:(_vm.first_surname),callback:function ($$v) {_vm.first_surname=$$v},expression:"first_surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"APELLIDO MATERNO","label-for":"second_surname"}},[_c('validation-provider',{attrs:{"rules":"required","name":"apellido materno"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"second_surname","placeholder":"Ingrese el apellido materno del cliente ...","state":errors.length > 0 ? false : null,"disabled":_vm.disabledFields,"trim":""},model:{value:(_vm.second_surname),callback:function ($$v) {_vm.second_surname=$$v},expression:"second_surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"NÚMERO DE DOCUMENTO","label-for":"document_number"}},[_c('validation-provider',{attrs:{"rules":"required","name":"número de documento"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"document_number","placeholder":"Ingrese el número de documento del cliente ...","state":errors.length > 0 ? false : null,"disabled":_vm.disabledFields,"trim":""},model:{value:(_vm.document_number),callback:function ($$v) {_vm.document_number=$$v},expression:"document_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"CORREO ELECTRÓNICO","label-for":"email"}},[_c('validation-provider',{attrs:{"rules":"required|email","name":"correo electrónico"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","placeholder":"Ingrese el correo electrónico del cliente ...","state":errors.length > 0 ? false : null,"disabled":_vm.disabledFields,"trim":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"CELULAR","label-for":"phone"}},[_c('validation-provider',{attrs:{"rules":"required","name":"celular"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","placeholder":"Ingrese el celular del cliente ...","state":errors.length > 0 ? false : null,"disabled":_vm.disabledFields,"trim":""},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(!_vm.disabledFields)?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"SELECCIONAR FECHA DE NACIMIENTO","label-for":"birthdate"}},[_c('validation-provider',{attrs:{"rules":"required","name":"fecha de nacimiento"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"birthdate","config":_vm.config2,"reset-button":"","close-button":"","placeholder":"YYYY-MM-DD","state":(errors.length > 0 ? false:null),"disabled":_vm.disabledFields},model:{value:(_vm.birthdate),callback:function ($$v) {_vm.birthdate=$$v},expression:"birthdate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,919968882)})],1)],1):_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"SELECCIONAR FECHA DE NACIMIENTO","label-for":"birthdate"}},[_c('b-form-input',{attrs:{"id":"birthdate","disabled":_vm.disabledFields},model:{value:(_vm.birthdateD),callback:function ($$v) {_vm.birthdateD=$$v},expression:"birthdateD"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"SELECCIONAR DÍA","label-for":"day"}},[_c('validation-provider',{attrs:{"rules":"required","name":"fecha de la reserva"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"day","config":_vm.config,"placeholder":"YYYY-MM-DD","state":(errors.length > 0 ? false:null)},model:{value:(_vm.day),callback:function ($$v) {_vm.day=$$v},expression:"day"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"SELECCIONAR HORARIO DISPONIBLE","label-for":"available_time"}},[_c('validation-provider',{attrs:{"rules":"required","name":"horario disponible"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"available_time","state":errors.length > 0 ? false : null,"options":_vm.available_times},model:{value:(_vm.available_time),callback:function ($$v) {_vm.available_time=$$v},expression:"available_time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"SELECCIONAR DOCTOR(A)","label-for":"doctor"}},[_c('validation-provider',{attrs:{"rules":"required","name":"doctor(a)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"doctor","state":errors.length > 0 ? false : null,"options":_vm.doctors},model:{value:(_vm.doctor),callback:function ($$v) {_vm.doctor=$$v},expression:"doctor"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" AGREGAR ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }