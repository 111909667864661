<template>
    <b-row>
        <b-col md="12">
            <b-card>
                <validation-observer ref="simpleRules">
                    <b-form>
                        <b-row>

                            <!-- Search Client On Based Document Number -->
                            <b-col md="12">
                                <b-input-group>
                                    <b-form-input v-model="documentNumberSearch" placeholder="Ingrese un número de documento para buscar al cliente ..."></b-form-input>

                                    <b-input-group-append>
                                        <b-button variant="success" @click="searchClientOnBasedDocumentNumber">
                                            <feather-icon icon="SearchIcon" />
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                                <hr>
                                <hr>
                            </b-col>


                            <!-- Name(s) -->
                            <b-col md="4">
                                <b-form-group
                                    label="NOMBRE(S)"
                                    label-for="names">
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="nombre(s)"
                                    >
                                        <b-form-input
                                            id="names"
                                            v-model="names"
                                            placeholder="Ingrese el nombre del cliente ..."
                                            :state="errors.length > 0 ? false : null"
                                            :disabled="disabledFields"
                                            trim
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- First Surname -->
                            <b-col md="4">
                                <b-form-group
                                    label="APELLIDO PATERNO"
                                    label-for="first_surname">
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="apellido paterno"
                                    >
                                        <b-form-input
                                            id="first_surname"
                                            v-model="first_surname"
                                            placeholder="Ingrese el apellido paterno del cliente ..."
                                            :state="errors.length > 0 ? false : null"
                                            :disabled="disabledFields"
                                            trim
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Second Surname -->
                            <b-col md="4">
                                <b-form-group
                                    label="APELLIDO MATERNO"
                                    label-for="second_surname">
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="apellido materno"
                                    >
                                        <b-form-input
                                            id="second_surname"
                                            v-model="second_surname"
                                            placeholder="Ingrese el apellido materno del cliente ..."
                                            :state="errors.length > 0 ? false : null"
                                            :disabled="disabledFields"
                                            trim
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Document Number -->
                            <b-col md="4">
                                <b-form-group
                                    label="NÚMERO DE DOCUMENTO"
                                    label-for="document_number">
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="número de documento"
                                    >
                                        <b-form-input
                                            id="document_number"
                                            v-model="document_number"
                                            placeholder="Ingrese el número de documento del cliente ..."
                                            :state="errors.length > 0 ? false : null"
                                            :disabled="disabledFields"
                                            trim
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Email -->
                            <b-col md="4">
                                <b-form-group
                                    label="CORREO ELECTRÓNICO"
                                    label-for="email">
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required|email"
                                        name="correo electrónico"
                                    >
                                        <b-form-input
                                            id="email"
                                            v-model="email"
                                            placeholder="Ingrese el correo electrónico del cliente ..."
                                            :state="errors.length > 0 ? false : null"
                                            :disabled="disabledFields"
                                            trim
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Phone -->
                            <b-col md="4">
                                <b-form-group
                                    label="CELULAR"
                                    label-for="phone">
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="celular"
                                    >
                                        <b-form-input
                                            id="phone"
                                            v-model="phone"
                                            placeholder="Ingrese el celular del cliente ..."
                                            :state="errors.length > 0 ? false : null"
                                            :disabled="disabledFields"
                                            trim
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Birthdate -->
                            <b-col md="4" v-if="!disabledFields">
                                <b-form-group
                                    label="SELECCIONAR FECHA DE NACIMIENTO"
                                    label-for="birthdate">
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="fecha de nacimiento"
                                    >
                                        <flat-pickr
                                            id="birthdate"
                                            v-model="birthdate"
                                            class="form-control"
                                            :config="config2"
                                            reset-button
                                            close-button
                                            placeholder="YYYY-MM-DD"
                                            :state="(errors.length > 0 ? false:null)"
                                            :disabled="disabledFields"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col md="4" v-else>
                                <b-form-group
                                    label="SELECCIONAR FECHA DE NACIMIENTO"
                                    label-for="birthdate">
                                    <b-form-input
                                        id="birthdate"
                                        v-model="birthdateD"
                                        :disabled="disabledFields"
                                    />
                                </b-form-group>
                            </b-col>

                            <!-- Booking Day -->
                            <b-col md="4">
                                <b-form-group
                                    label="SELECCIONAR DÍA"
                                    label-for="day">
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="fecha de la reserva"
                                    >
                                        <flat-pickr
                                            id="day"
                                            v-model="day"
                                            class="form-control"
                                            :config="config"
                                            placeholder="YYYY-MM-DD"
                                            :state="(errors.length > 0 ? false:null)"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Available Time -->
                            <b-col md="4">
                                <b-form-group
                                    label="SELECCIONAR HORARIO DISPONIBLE"
                                    label-for="available_time">
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="horario disponible"
                                    >
                                        <v-select
                                            id="available_time"
                                            v-model="available_time"
                                            :state="errors.length > 0 ? false : null"
                                            :options="available_times"
                                        />

                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Doctor -->
                            <b-col md="4">
                                <b-form-group
                                    label="SELECCIONAR DOCTOR(A)"
                                    label-for="doctor">
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="doctor(a)"
                                    >
                                        <v-select
                                            id="doctor"
                                            v-model="doctor"
                                            :state="errors.length > 0 ? false : null"
                                            :options="doctors"
                                        />

                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Submit Button -->
                            <b-col cols="12">
                                <b-button
                                    variant="primary"
                                    type="submit"
                                    @click.prevent="validationForm"
                                >
                                    AGREGAR
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-form>
                </validation-observer>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { Spanish } from 'flatpickr/dist/l10n/es';
    import flatPickr from 'vue-flatpickr-component';
    import Ripple from 'vue-ripple-directive';
    import { required } from '@validations';
    import Toast from 'vue-toastification';
    import vSelect from 'vue-select';
    import moment from 'moment';
    import axios from '@axios';
    import Vue from 'vue';

    Vue.use(Toast);

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,

            flatPickr,
            vSelect
        },
        directives: {
            Ripple
        },
        data() {
            return {
                required,
                documentNumberSearch: '',
                disabledFields: false,

                clientId: null,
                names: '',
                first_surname: '',
                second_surname: '',
                document_number: '',
                email: '',
                phone: '',
                birthdate: null,
                birthdateD: null,

                available_times: [],
                doctors: [],
                available_time: null,
                doctor: null,
                day: null,

                config: {
                    enableTime: false,
                    locale: Spanish,
                    minDate: 'today'
                },
                config2: {
                    allowInput: true,
                    enableTime: false,
                    locale: Spanish,
                    maxDate: 'today'
                }
            }
        },
        beforeMount (){
            this.getAvailableTimes();
            this.getDoctors();
        },
        methods: {
            async searchClientOnBasedDocumentNumber () {
                if (this.documentNumberSearch){

                    const response = await axios.get(`/client/search?document_number=${this.documentNumberSearch}`);
                    
                    const { data } = response;
                    let message, variant, icon;

                    if (data){

                        message = 'La información del cliente ha sido cargada correctamente.';
                        variant = 'success';
                        icon = 'CheckIcon';

                        this.disabledFields = true;

                        this.clientId = data.id;
                        this.names = data.names;
                        this.first_surname = data.first_surname;
                        this.second_surname = data.second_surname;
                        this.document_number = data.document_number;
                        this.email = data.email;
                        this.phone = data.phone;
                        this.birthdate = null;
                        this.birthdateD = moment(data.birthdate).format('DD/MM/YYYY');

                    } else {

                        message = `No se encontró ningún cliente con el número de documento: ${this.documentNumberSearch}`;
                        variant = 'danger';
                        icon = 'AlertTriangleIcon';

                        this.clearFields();
                        
                    }

                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: message,
                            icon,
                            variant
                        }
                    });

                } else {

                    this.clearFields();

                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Debe ingresar un número de documento.',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    });

                }
            },
            async getAvailableTimes (){
                let response = await axios.get('/available-times/select');
                this.available_times = response.data;
            },
            async getDoctors (){
                let response = await axios.get('/doctors/select');
                this.doctors = response.data;
            },
            clearFields (withAll = false){
                
                this.disabledFields = false;

                this.clientId = null;
                this.names = '';
                this.first_surname = '';
                this.second_surname = '';
                this.document_number = '';
                this.email = '';
                this.phone = '';
                this.birthdate = null;
                this.birthdateD = null;

                if (withAll){
                    this.available_time = null;
                    this.doctor = null;
                    this.day = null;
                }

                this.$refs['simpleRules'].reset();

            },
            validationForm() {
                this.$refs.simpleRules.validate()
                    .then(success => {
                        if (success) {

                            const data = {
                                clientId: this.clientId,
                                names: this.names,
                                first_surname: this.first_surname,
                                second_surname: this.second_surname,
                                document_number: this.document_number,
                                email: this.email,
                                phone: this.phone,
                                birthdate: this.birthdate,
                                day: this.day,
                                available_time_id: this.available_time.value,
                                doctor_id: this.doctor.value
                            };

                            axios.post('/booking/create', data)
                                .then(response => {

                                    this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: response.data.message,
                                            icon: 'CheckIcon',
                                            variant: 'success'
                                        }
                                    });

                                    this.clearFields(true);

                                    setTimeout(() => {
                                        this.$router.push({ name: 'booking-edit', params: { id: response.data.id } });
                                    }, 3000);

                                })
                                .catch( (err) => {
                                    let message = err.response.data.message ? err.response.data.message : 'Error al crear el cupón de descuento.';
                                    this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: message,
                                            icon: 'AlertTriangleIcon',
                                            variant: 'danger'
                                        }
                                    });
                                });
                            
                        }
                    });
            }
        }
    }

</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>